import React from 'react';
import { settings } from "../../config/settings";
import ControlService from "./index";
import utilily from "../../utils";
import { toast } from 'react-toastify';
// import Message from "../component/Message";

const Message = React.lazy(() => import('../component/Message'));
const uuidv4 = require('uuid/v4');
function setWorkBenchDataSource(result, token) {
    if (token.enablegit) {
        result = settings.DATASOURCE.GITHUB;
    }

    return result;
}
export const setPageVariablesFromMenu = (ResultApiParam, apiparam, token, model, props) => {

    var currentUrl = window.location.pathname;
    if (currentUrl.indexOf('?') > -1) {
        currentUrl = currentUrl.split('?')[0];
    }
    if (currentUrl.indexOf('/forms/Edit') > -1) {
        currentUrl = "/forms/Edit";

    }
    const menu = token && token.menu ? token.menu : {};
    const page = ControlService().find(menu['Nav'], currentUrl.substring(1));
    if (apiparam && Array.isArray(ResultApiParam)) {
        ResultApiParam.forEach(option => {
            if (option.paramtype && option.paramtype === "guid") {
                apiparam[option.key] = uuidv4();
            } else if (option.paramtype && option.paramtype === 'userid') {
                apiparam[option.key] = token.user.id;
            } else if (option.paramtype && option.paramtype === 'email') {
                apiparam[option.key] = token.user.email;
            } else if (option.paramtype && option.paramtype === 'querystring') {
                let value = ControlService().GetParamUrl(option.value);
                if (value) {
                    apiparam[option.key] = value;
                }
            } else if ((option.paramtype && option.paramtype === 'modelvariable') || option.isdatamodel) {
                apiparam[option.key] = model[option.value];
            }
            else if (option.paramtype && option.paramtype === 'date') {
                apiparam[option.key] = new Date().toJSON();
            } else if (option.paramtype && option.paramtype === 'role') {
                apiparam[option.key] = token.user.roles.toString();
            } else if (option.paramtype && option.paramtype === 'rolename') {
                const role = token.user.roles.find(x => x === token.user.activerole)

                apiparam[option.key] = role ? role.rolename : '';
            } else if (option.paramtype && option.paramtype === 'model') {
                apiparam[option.key] = model;
            } else if (option.paramtype && option.paramtype === 'parentformmodel') {
                const _parentData = props.data[option.formid + 'write'] ? props.data[option.formid + 'write'] : {};
                apiparam[option.key] = _parentData[option.value];
            } else if (option.paramtype && option.paramtype === "static") {
                apiparam[option.key] = option.value;
            } else if (option.paramtype && option.paramtype == 'getglobalvariable') {
                let globalvariable = localStorage.getItem(option.value);
                if (globalvariable === 'null' || globalvariable === 'undefined') {
                    globalvariable = null;
                }
                apiparam[option.key] = globalvariable;
                // console.log(value);
            } else {
                let param = ControlService().GetParamUrl(option.value);
                if (param) {
                    apiparam[option.key] = param;
                } else if (page && Array.isArray(page.Params) && !option.paramtype) {
                    param = page.Params.find(x => x.name == option.value || x.attribute == option.value);
                    if (param) {
                        apiparam[option.key] = param.value;
                    }
                }
            }
        })
    }
}
function setModelFromParams(model, apiparam) {
    if (model && apiparam && Object.keys(apiparam).length > 0) {
        model = typeof model === 'object' ? model : {};
        for (let option in apiparam) {
            const value = apiparam[option];
            if (Object.keys(model).includes(option)) {
                model[option] = value;
            }
        }
    }
    return model;
}
function removebookmark(action, props) {
    var { Bookmark } = props;

    // Bookmark = Bookmark.filter(x => x.id != this.BookmarkId);
    // this.BookmarkId = null;

    var isbookmarked = null;
    const currentUrl = action.RedirectUrl;
    // const menu = this.auth.Menu;
    const bookmark = props.Bookmark ? props.Bookmark.filter(x => x.url == currentUrl.substring(1)) : [];
    // const page = this._controlService.find(menu['Nav'], currentUrl.substring(1));
    if (bookmark) {
        bookmark.forEach(item => {
            const param = item.params.find(x => x.name === action.PrimaryKey && x.value == props.data[action.PrimaryKey]);
            if (param) {
                isbookmarked = item;
            }
        })
    }
    if (isbookmarked) {
        Bookmark = Bookmark.filter(x => x !== isbookmarked);
        props.setBookMark(Bookmark);
    }
}

export const _isTokenValid = (token) => {
    return token && token.expiresOn ? new Date(token.expiresOn).getTime() > new Date().getTime() : false;
}
export const loggedInDataSource = (token, application) => {
    var result = "";
    switch (token.loginMethod) {
        case settings.LOGINMETHOD.FIREBASE:
            result = settings.DATASOURCE.FIREBASESTORE;
            break;
        case settings.LOGINMETHOD.AWS:
            result = settings.DATASOURCE.S3
            break;
        case settings.LOGINMETHOD.AZUREAD:
            result = settings.DATASOURCE.BLOB;
            break;
        case settings.LOGINMETHOD.AZUREB2C:
            result = settings.DATASOURCE.BLOB;
            break;
        default:
    }
    return application ? result : setWorkBenchDataSource(result, token);
}
export const isGuid = (stringToTest) => {
    if (stringToTest[0] === "{") {
        stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
}
export const isPhoneNumber = (text) => {
    let _return = false;
    if (/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(text) === true || /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(text) === true || /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(text) === true || /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(text) === true) {
        _return = true;
    }

    return _return;
}
export const isWebAddress = (text) => {
    // eslint-disable-next-line
    let reg = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return reg.test(text);
}
export const GetParamUrl = (_k) => {// get parameter by key
    let sPageURL = decodeURIComponent(window.location.search.substr(1)),
        sURLVariables = sPageURL.split('&').map(_v => _v.replace(/\=/, '&').split('&'));
    let _result = _k ? sURLVariables.find(_v => _v[0].toLowerCase() === _k.toString().toLowerCase()) : null;
    return _result ? _result[1] : null;
}
export const find = (source, url, keyValue) => {
    for (var key in source) {
        var item = source[key];
        if (item[keyValue ? keyValue : 'Url'] === url)
            return item;

        // Item not returned yet. Search its children by recursive call.
        if (item.children) {
            var subresult = ControlService().find(item.children, url, keyValue);

            // If the item was found in the subchildren, return it.
            if (subresult)
                return subresult;
        }
    }
    return null;
}
export const setPageVariables = (apiparam) => {
    if (apiparam) {
        for (let option in apiparam) {
            const value = ControlService().GetParamUrl(apiparam[option]);
            if (value) {
                apiparam[option] = value;
            }
        }
    }
    return apiparam;
}
export const GenerateDataJson = (control, datamodel, formid, forcereset) => {
    datamodel = control ? datamodel : {};
    control = Array.isArray(control) ? control : [];
    try {
        for (var index = 0; index < control.length; index++) {
            if ((control[index].type === 'checkbox' && datamodel[control[index].key] == false) || datamodel[control[index].key] === '' || datamodel[control[index].key] === undefined || datamodel[control[index].key] === null || forcereset) {
                if (control[index].type === 'checkbox') {
                    if (control[index].customvalue === 'yes' && control[index].Truevalue != "") {
                        datamodel[control[index].key] = control[index].defaultvalue ? control[index].defaultvalue : control[index].Falsevalue;
                    } else {
                        datamodel[control[index].key] = control[index].defaultvalue ? true : false;
                    }

                } else if (control[index].type === 'expression') {
                    datamodel[control[index].key] = control[index].defaultvalue ? parseInt(control[index].defaultvalue) : 0;
                } else if (control[index].type === 'groupcalc') {
                    datamodel[control[index].key] = control[index].defaultvalue ? parseInt(control[index].defaultvalue) : 0;
                } else if (control[index].type === 'grid'
                    || control[index].type === 'list'
                    || control[index].type === 'checkboxlist'
                    || control[index].type === 'multiselect') {
                    datamodel[control[index].key] = [];
                } else if (control[index].type === 'number'
                    || control[index].type === 'googleplaces'
                    || control[index].type === 'date'
                ) {
                    datamodel[control[index].key] = control[index].defaultvalue || control[index].defaultvalue == 0 ? control[index].defaultvalue : null;
                }
                else if (control[index].type === 'label') {
                    if (control[index].viewtype !== 'name') {
                        datamodel[control[index].key] = "";
                    }
                }
                else if (control[index].type === 'cardgrid'
                    || control[index].type === 'kanban'
                    || control[index].type === 'map'
                    || control[index].type === 'advancegrid'
                    || control[index].type === 'table'
                    || control[index].type === 'calender') {
                    if (control[index].filterresult !== true)
                        datamodel[control[index].key] = [];
                    else
                        delete datamodel[control[index].key]
                } else if (control[index].type === 'button') {
                    control[index].notificationformid = formid;
                    if (!control[index].enableautovalidateonsubmit && !control[index].require) {
                        delete datamodel[control[index].key];
                    } else {
                        datamodel[control[index].key] = '';
                    }
                }
                else if (control[index].type === 'import') {
                    control[index].notificationformid = formid;
                }
                else if (control[index].type === 'bookmark') {
                    delete datamodel[control[index].key]
                }
                else if (control[index].type === 'dropdown2') {
                    if (control[index].selectiontype === 'single') {
                        datamodel[control[index].key] = control[index].defaultvalue || control[index].defaultvalue === 0 ? control[index].defaultvalue : null;
                    } else {
                        datamodel[control[index].key] = control[index].defaultvalue ? JSON.parse(control[index].defaultvalue) : [];
                    }
                }
                else if (control[index].haschildren === true) {
                    if (control[index].type === 'tab') {
                        datamodel[control[index].key] = "";
                    }
                    GenerateDataJson(control[index].children, datamodel, formid, forcereset);
                } else {
                    datamodel[control[index].key] = control[index].defaultvalue ? control[index].defaultvalue : '';
                }
            }
            // if (form) {
            //     this.addValidation(control[index], form);
            // }
        }
    } catch (e) {

    }
}
export const deserializeForm = (response) => {
    var keys = ["controls", "CreateEndpointParams", "UpdateEndpointParams", "GetEndpointParams", "ListEndpointParams", "DeleteEndpointParams", "ModelJson", "ModelJson", "Notification", "FilterModelJson", "getheaders", "FilterUIJson"]
    keys.forEach(key => {
        if (response[key] && typeof response[key] === 'string') {
            response[key] = JSON.parse(response[key]);
        }
    })
}
export const ServiceRequest = (ApiUrl, ApiParams, control, token, model, pageparams, advancesearchapimapping, callback, props) => {
    let _apiparam = {};
    var url = ApiUrl;
    // if (url) {
    if (Array.isArray(ApiParams)) {
        ApiParams.forEach(option => {
            _apiparam[option.key] = option.value;
        });
    } else {
        _apiparam = ApiParams;
    }
    if (advancesearchapimapping) {
        advancesearchapimapping.forEach(option => {
            _apiparam[option.ApiParam] = pageparams[option.advancesearchfield];
        })
    }
    //_apiparam = ControlService().setPageVariables(_apiparam);

    var ResultApiParam = [];
    if (control.apitype == 'apiparam') {
        ResultApiParam = control.apiparam;
    } else {
        ResultApiParam = control.ResultApiParam || control.apiparam || control.FilterEndpointParams;
    }
    if (!ResultApiParam && Array.isArray(ApiParams)) {
        ResultApiParam = ApiParams;
    }
    if (typeof model == 'string') {
        try {
            model = JSON.parse(model);
            if (control.method == 'function') {
                setPageVariablesFromMenu(ResultApiParam, _apiparam, token, model, props);
            } else {
                setPageVariablesFromMenu(ResultApiParam, _apiparam, token, model, props);
                model = setModelFromParams(model, _apiparam);
                // model = ControlService().setPageVariables(model);
            }
            model = JSON.stringify(model);
        } catch (e) {

        }
    } else {
        setPageVariablesFromMenu(ResultApiParam, _apiparam, token, model, props);
    }
    if (control.enablerowquery && (control.rowquery || control.rawquery) && Object.keys(_apiparam).length > 0) {
        var apiparam = ResultApiParam;
        var query = control.rowquery || control.rawquery;
        apiparam.forEach(option => {
            let modelval = null;
            modelval = ControlService().isEmptyorNull(_apiparam[option.key]) ? "" : _apiparam[option.key];
            if (typeof modelval === 'object') {
                try {
                    modelval = JSON.stringify(modelval);
                } catch {
                    modelval = ControlService().isEmptyorNull(_apiparam[option.key]) ? "" : _apiparam[option.key];
                }
            }
            var param = "{{" + option.key + "}}";
            if (query.indexOf(param) > -1) {
                query = query.replace(param, modelval);
                delete _apiparam[option.key];
            }
        })
        if (control.method.toLowerCase() === 'post' || control.method.toLowerCase() === 'update') {
            model = JSON.parse(query);
        } else {
            _apiparam.query = query;
        }
    }
    return utilily().servicerequest(control, url, _apiparam, model, token, pageparams, callback)
    // }
}
export const SetParamUrl = (_k, v) => {// replace and add new parameters
    let arrParams = window.location.search !== '' ? decodeURIComponent(window.location.search.substr(1)).split('&').map(_v => _v.split('=')) : Array();
    let index = arrParams.findIndex((_v) => _v[0] === _k);
    index = index !== -1 ? index : arrParams.length;
    v === null ? arrParams = arrParams.filter((_v, _i) => _i !== index) : arrParams[index] = [_k, v];
    let _search = encodeURIComponent(arrParams.map(_v => _v.join('=')).join('&'));

    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + (arrParams.length > 0 ? '?' + _search : '');
    // window.location = newurl; //reload 
    if (window.history.pushState) { // without reload  
        window.history.pushState({ path: newurl }, null, newurl);
    }
}
export const addbookmark = (action, props) => {
    if (ControlService().isbookmarked(action)) {
        removebookmark(action, props);
    } else {
        let currentUrl = action.RedirectUrl;

        var menu = props.token.menu;

        var page = find(menu['Nav'], currentUrl.substring(1));
        var item = {
            "id": uuidv4(),
            "url": page.Url,
            "title": props.data[action.titlebinding] ? props.data[action.titlebinding] : page.Title,
            "subtitle": props.data[action.subtitlebinding] != 'undefined' && props.data[action.subtitlebinding] != null ? props.data[action.subtitlebinding] : action.subtitlebinding,
            "icon": action.WebIcon ? action.WebIcon : "",
            "params": page.Params
        };
        var BookMark = Array.isArray(props.Bookmark) ? props.Bookmark : [];
        BookMark.push(item);
        props.setBookMark(BookMark);

        return item.id;
    }
}
export const submitAction = async (action, props, context) => {
    let url = "";
    var isApiCalling = true;
    let apiparam = {};
    debugger;
    url = action.apiurl;
    var dataModel = props.data;
    if (!action.enablerowquery) {
        if (action.apiparam) {
            action.apiparam.forEach(option => {
                if (option.ispagevarible || option.paramtype == "querystring") {
                    var pageVal = GetParamUrl(option.value)
                    apiparam[option.key] = pageVal;
                } else if (option.ismodelvarible || option.paramtype == "modelvariable") {
                    apiparam[option.key] = props.data[option.value];
                } else {
                    apiparam[option.key] = option.value;
                }
            })
        }
        //this._controlService.setPageVarible(action.apiparam, apiparam);
    } else {
        var rowQuery = action.rowquery;
        if (action.apiparam) {
            action.apiparam.forEach(option => {
                if (!option.ispagevarible && option.paramtype != "querystring") {
                    rowQuery = rowQuery.replace("{{" + option.key + "}}", props.data[option.value] ? props.data[option.value] : '');
                } else {
                    var pageVal = ControlService().GetParamUrl(option.value)
                    if (pageVal) {
                        rowQuery = rowQuery.replace("{{" + option.key + "}}", pageVal);
                    } else {
                        rowQuery = rowQuery.replace("{{" + option.key + "}}", option.value);
                    }
                }
            })
        }
        dataModel = JSON.parse(rowQuery);
    }
    ControlService().ServiceRequest(url, apiparam, action, props.token, dataModel)
        .then((res) => {
            res = ControlService().ApiResponse(res);
            props.dataIsLoading(false);
            isApiCalling = false;
            var data = props.data;
            var redirect = true;
            if (action.mappingfieldforprimarykey) {
                if (res[action.mappingfieldforprimarykey]) {
                    data[action.PrimaryKey] = res[action.mappingfieldforprimarykey];
                } else {
                    redirect = false;
                }
            }
            if ((action.ActionType === 'submitredir') && redirect) {
                if (action.PrimaryKey) {
                    let path = action.RedirectUrl + "?" + action.PrimaryKey + '=' + data[action.PrimaryKey];
                    context.history.push(path);
                } else {
                    let path = action.RedirectUrl;
                    context.history.push(path);
                }
            } else if ((action.ActionType === 'submitredirNew') && redirect) {
                if (action.successmessage) {
                    toast.success(<Message icon={"fa fa-thumbs-o-up"} title={"Awesome"} message={action.successmessage ? action.successmessage : "Information saved successfully"} type="success" />)
                }
                if (action.PrimaryKey) {
                    let path = action.RedirectUrl + "?" + action.PrimaryKey + '=' + data[action.PrimaryKey];
                    //context.history.push(path);
                    window.open(path, '_blank');

                } else {
                    let path = action.RedirectUrl;
                    //context.history.push(path);
                    window.open(path, '_blank');
                }
            } else if (action.ActionType === 'submitredirNewWindow' && redirect) {
                if (action.successmessage) {
                    toast.success(<Message icon={"fa fa-thumbs-o-up"} title={"Awesome"} message={action.successmessage ? action.successmessage : "Information saved successfully"} type="success" />)
                }
                if (action.PrimaryKey) {
                    let path = action.RedirectUrl + "?" + action.PrimaryKey + '=' + data[action.PrimaryKey];
                    window.open(path, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=100,width=1200,height=700')
                } else {
                    let path = action.RedirectUrl;
                    window.open(path, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=100,width=1200,height=700')
                }
            } else if ((action.ActionType === 'submitredirNewWindow' || action.ActionType === 'submitredir' || action.ActionType === 'submitredirNew' || action.ActionType === 'submitredirboth') && !redirect) {
                toast.error(<Message icon={"fa fa-thumbs-o-down"} title={"Something went wrong"} message={"Try again"} type="error" />)
            }
            else if (action.ActionType === 'submitbookamrk' && redirect) {
                // this.setParam(action);
                addbookmark(action, props);
                if (action.successmessage) {
                    toast.success(<Message icon={"fa fa-thumbs-o-up"} title={"Awesome"} message={action.successmessage ? action.successmessage : "Information saved successfully"} type="success" />)
                }
            } else if (action.ActionType === 'submitbookamrk' && !redirect) {

                toast.error(<Message icon={"fa fa-thumbs-o-down"} title={"Oops!"} message={res.message} type="error" />)
            } else if (action.ActionType === 'submitsidebar') {
                if (action.successmessage) {
                    toast.success(<Message icon={"fa fa-thumbs-o-up"} title={"Awesome"} message={action.successmessage ? action.successmessage : "Information saved successfully"} type="success" />)
                }
                SetParamUrl(action.primarykeynode ? action.primarykeynode : action.PrimaryKey, props.data[action.PrimaryKey]);

                props.resetSidebar(true);
                if (action.sidebarformidfield) {
                    props.setSidebarFormId(props.data[action.sidebarformidfield]);
                } else {
                    props.setSidebarFormId(action.sidebarformid);
                }
            } else {
                if (res.status === "success" || res.statusCode === 200) {
                    toast.success(<Message icon={"fa fa-thumbs-o-up"} title={"Awesome"} message={action.successmessage ? action.successmessage : res.message} type="success" />)
                } else if (res.status === "error") {
                    toast.error(<Message icon={"fa fa-thumbs-o-down"} title={"Oops!"} message={res.message} type="error" />)
                }
            }
            if (action.refreshcomponentonclose && action.refreshcomponenformid) {
                props.update(action.refreshcomponentonclose + '_refresh', uuidv4(), action.refreshcomponenformid + 'write')
            } else if (action.refreshcomponentonclose) {
                if (action.refreshcomponentonclose.toLowerCase().trim() == 'reload')
                    window.location.reload();
                else
                    props.setreload({ activeformid: props.formid, formid: action.refreshcomponentonclose.trim().toLowerCase(), refresh: true });
            } else if (action.refreshcomponenformid) {
                props.setreload({ activeformid: props.formid, formid: action.refreshcomponenformid, refresh: true });
            }

            return isApiCalling
        })
        .catch(err => {
            isApiCalling = false;
            props.dataIsLoading(false);
            toast.error(<Message icon={"fa fa-thumbs-o-down"} title={"Oops!"} message={"An error occurred, please try again later!"} type="error" />)
            return isApiCalling
        });
}